import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import Container from "../components/Dashboard/Container";
import colors from "../styles/colors";
import Logo from "../components/Question/Logo";
import ConnectWallet from "../components/Question/ConnectWallet";
import forest from "../resources/img/dashboard/dashboard-bg.jpg";
import storage from "../services/storage";
import QuestionTitle from "../components/Question/QuestionTitle";
import breakpoints from "../styles/breakpoints";
import StepsPanel from "../components/Dashboard/StepsPanel";
import CardsPanel from "../components/Dashboard/CardsPanel";
import dashboard from "../services/lambda/dashboard";
import IntroPanel from "../components/Dashboard/IntroPanel";
import Frame from "../components/Question/Frame";
import Overlay from "../components/Dashboard/Overlay";
import accessory from "../services/lambda/accessory";

const PageBackground = styled.div`
  background: url(${forest}) no-repeat ${colors.darkGreen};
  background-size: 100% auto;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
`;

const PageContent = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 0;
  margin: auto;
  text-align: center;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  margin: 32px auto 12px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ConnectContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-bottom: 40px;
`;

const DesktopOnly = styled.div`
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const MobileOnly = styled.div`
  display: flex;

  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const DashboardContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const DashboardContent = styled.div`
`;

const DashboardPage = ({location}) => {
  const [dashboardData, setDashboardData] = useState({});
  const [walletAddress, setWalletAddress] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [selectedStep, setSelectedStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showUnlockAccessorySuccess, setShowUnlockAccessorySuccess] = useState(false);
  // const [hasPandas, setHasPandas] = useState(true);

  const getDashboardItems = async (accountData, updatedCount = 0) => {
    const newCount = updatedCount + 1;
    const MAX_RETRIES = 15;

    if (hasErrors) {
      return;
    }

    const dashboardItems = await dashboard.getDashboard(accountData);

    const keys = Object.keys(dashboardItems);

    if (keys.length < 1) {
      window.setTimeout(async function () {
        if (hasErrors) {
          return;
        }

        if (newCount >= MAX_RETRIES) {
          setHasErrors(true);

          return;
        }

        await getDashboardItems(accountData, newCount);
      }, 5000, newCount);
    } else {
      const passes = JSON.parse(dashboardItems["passes"]);
      const pandaPass = passes["panda"];
      const apiHasErrors = dashboardItems["errors"] === true;

      setDashboardData(dashboardItems);

      if (!apiHasErrors) {
        setLoading(false);
      } else {
        setLoading(false);
        setHasErrors(true);
      }
    }

    return dashboardItems;
  }

  useEffect(async () => {
    const accountData = storage.getAccount();

    if (accountData && typeof accountData !== "undefined") {
      setWalletAddress(accountData);

      await getDashboardItems(accountData);

      // if (!dashboardItems
      //   || typeof dashboardItems !== "object"
      //   || Object.keys(dashboardItems).length === 0) {
      //   window.location.href = "/login";
      // }
    } else {
      window.location.href = "/login";
    }
  }, []);

  const onConnect = (address) => {
    setWalletAddress(address);
    setHasErrors(false);
  };

  const updateSelectedStep = (i) => {
    let newStep;

    if (selectedStep && selectedStep === i) {
      newStep = 1;
    } else {
      newStep = i;
    }

    setSelectedStep(newStep);
  }

  const unlockAccessory = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    if (!walletAddress) {
      setHasErrors(true);
      setIsProcessing(false);

      return;
    }

    try {
      await accessory.unlockAccessory(walletAddress);
    } catch (e) {
      setIsProcessing(false);
      setHasErrors(true);

      return;
    }

    setIsSuccess(true);

    setShowUnlockAccessorySuccess(true);

    // setHasErrors(isCorrect !== true);

    // window.setTimeout(function () {
    //   setShowUnlockAccessorySuccess(false);
    // }, 2000);

    setIsProcessing(false);
  }

  const closePopup = () => {
    setHasErrors(false);
    setShowUnlockAccessorySuccess(false);
  }

  const backToHome = () => {
    window.location.href = "/";
  }

  return (
    <Layout footer={"true"}>
      <Seo title={"Panda Dynasty - Dashboard"}
           description={"Panda Dynasty - Dashboard"}
           path={location.pathname}/>
      <PageBackground>
        <PageContent>
          <Container>
            <HeaderContainer>
              <Logo/>
              <DesktopOnly>
                <ConnectContainer>
                  <ConnectWallet callback={onConnect}/>
                </ConnectContainer>
              </DesktopOnly>
            </HeaderContainer>
            <DashboardContainer>
              <DashboardContent>
                <DesktopOnly>
                  <Overlay title={(hasErrors) ? "An error occurred!" : "Loading..."}
                           show={(loading === true || hasErrors === true) && "true"}
                           action={(loading === false && hasErrors === true) && closePopup}
                           actionCta={(loading === false && hasErrors === true) && "OK"}>
                    {(hasErrors) ? "Something failed! Please try again." : "Fetching collection and passes data."}
                  </Overlay>
                  <Overlay title={"Congrats!"} show={showUnlockAccessorySuccess === true && "true"} action={closePopup} actionCta={"OK"}>
                    <b>You have unlocked Pass #6 Background Accessory.</b><br />Check back in a few minutes to discover your washed pandas and new accessories!
                  </Overlay>
                  {loading === false && <>
                    <StepsPanel items={dashboardData} update={updateSelectedStep} selectedStep={selectedStep}/>
                    <IntroPanel selectedStep={selectedStep}/>
                    <CardsPanel items={dashboardData} filter={selectedStep} unlockAccessory={unlockAccessory}/>
                  </>}
                </DesktopOnly>
                <MobileOnly>
                  <Frame>
                    <QuestionTitle>Visit this page on a laptop or desktop screen to get started!</QuestionTitle>
                  </Frame>
                </MobileOnly>
              </DashboardContent>
            </DashboardContainer>
          </Container>
        </PageContent>
      </PageBackground>
    </Layout>
  )
};

export default DashboardPage;
