import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import stepChecked from "../../resources/img/dashboard/step-checked.svg";
import stepUnchecked from "../../resources/img/dashboard/step-unchecked.svg";
import ButtonLink from "../Home/ButtonLink";

const StepItem = styled.div`
  border: 1px solid ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 8px;
  min-width: 125px;
  max-width: 125px;
  min-height: 80px;
  cursor: pointer;

  &:hover {
    background: ${colors.darkGreen};
  }

  background: ${(props) => props.selected === "true" ? colors.darkGreen : "transparent"};
`;

const StepRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StepNumber = styled.div`
  ${fonts.gloryBold};
  font-size: 16px;
  line-height: 16px;
`;

const StepTitle = styled.div`
  ${fonts.gloryRegular};
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  text-align: left;
`;

const StepStatus = styled.div`
  width: 16px;
  height: 16px;
  background: url(${(props) => props.done === "true" ? stepChecked : stepUnchecked}) no-repeat;
`;

const Step = ({number, title, completed, onClick, selected}) => {
  return (
    <StepItem onClick={(e) => onClick(e, number)}
              selected={selected === number ? "true" : "false"}>
      <StepRow>
        <StepNumber>
          {number}
        </StepNumber>
        <StepStatus done={completed === true && "true"}/>
      </StepRow>
      <StepTitle>
        {title}
      </StepTitle>
    </StepItem>
  );
}

export default Step
