import React, {useEffect, useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import cardImage from "../../resources/img/dashboard/card.png";
import ActionFramePanel from "./ActionFramePanel";

const CardsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const CardsContent = styled.div`
  display: flex;
  max-width: 732px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Card = styled.div`
  background: url(${cardImage}) no-repeat;
  background-size: 100% auto;
  width: 173px;
  height: 238px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

const CardImage = styled.img`
  width: 120px;
  height: 120px;
  margin-top: 22px;
`;

const CardContent = styled.div`
  ${fonts.gloryBold};
  font-size: 18px;
  line-height: 24px;
  color: ${colors.grey};
  text-align: left;
  width: 120px;
  margin: 10px auto 0 auto;
`;

const CardsPanel = ({items, filter, unlockAccessory}) => {
  const [selectedItem, setSelectedItem] = useState({});

  let passes = {};

  if (items && typeof items["passes"] !== "undefined") {
    passes = JSON.parse(items["passes"]);
  }

  const filters = {
    1: "pandas",
    2: "pumpkins",
    3: "Zova",
    4: "Golden Bamboo",
    5: "Washing Machine",
    6: "Accessory",
    7: "Time Machine",
    8: "tickets"
  }

  let itemsData = [];

  let itemKeys = [];
  let collection = {};

  if (items && typeof items["collection"] !== "undefined") {
    collection = JSON.parse(items["collection"]);

    itemKeys = Object.keys(collection);
  }

  for (let i = 0; i < itemKeys.length; i++) {
    const itemKey = itemKeys[i];

    const collectionItems = collection[itemKey];

    collectionItems.map((collectionItem) => {
      let itemData = collectionItem;

      itemData.type = itemKey;

      let name = itemData.metadata.name;

      if (itemKey === "pumpkins") {
        name = `Pumpkin #${itemData.id}`;
      } else {
        itemData.metadata.attributes.map((attribute) => {
          if (attribute["pass_type"] === "Pass Type") {
            name = `${attribute["value"]} #${itemData.id}`;
          }
        });
      }

      itemData.name = name;

      itemsData.push(itemData);
    });
  }

  itemsData = itemsData.sort((a, b) => {
    let typeA;
    let typeB;

    if (b.type === "pandas" && a.type !== "pandas") {
      return 1;
    }

    if (b.type === "pumpkins" && a.type !== "pumpkins") {
      return 1;
    }

    a.metadata.attributes.map((attribute) => {
      if (attribute["pass_type"] === "Pass Type") {
        typeA = attribute["value"];
      }
    });

    b.metadata.attributes.map((attribute) => {
      if (attribute["pass_type"] === "Pass Type") {
        typeB = attribute["value"];
      }
    });

    if (typeA === typeB) {
      let idA = a.id * 1;
      let idB = b.id * 1;

      return idA > idB ? 1 : -1;
    }

    return typeA > typeB ? 1 : -1;
  });

  if (filter) {
    itemsData = itemsData.filter((item) => {
      const filterName = filters[filter];

      if (item.type === filterName) {
        return true;
      }

      let itemType;

      item.metadata.attributes.map((attribute) => {
        if (attribute["pass_type"] === "Pass Type") {
          itemType = attribute["value"];
        }
      });

      return itemType === filterName;
    });
  }


  useEffect(() => {
    setSelectedItem(itemsData[0]);
  }, [items]);

  const onItemClick = (e, item) => {
    e.preventDefault();

    setSelectedItem(item);
  }

  let date = new Date();

  date.setMinutes(0, 0, 0, 0);
  date.setSeconds(0, 0, 0, 0);

  const imageCacheTimestamp = Date.parse(date.toString());

  return (
    <CardsContainer>
      <CardsContent>
        {itemsData.map((item, index) => {
          return (
            <Card key={index} onClick={(e) => onItemClick(e, item)}>
              <CardImage
                src={`${item.metadata.image}${item.metadata.image.includes("pandadynasty") ? "?" + imageCacheTimestamp : ""}`}
                alt={item.type}/>
              <CardContent>
                {item.name}
              </CardContent>
            </Card>
          )
        })}
      </CardsContent>
      <ActionFramePanel item={selectedItem} unlockAccessory={unlockAccessory}/>
    </CardsContainer>
  );
}

export default CardsPanel
