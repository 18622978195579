import React from "react"
import styled from "styled-components"
import Step from "./Step";

const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
`;

const StepsPanel = ({items, update, selectedStep}) => {
  let passes = {};

  if (items && typeof items["passes"] !== "undefined") {
    passes = JSON.parse(items["passes"]);
  }

  const toggleStep = (e, i) => {
    e.preventDefault();

    update(i);
  }

  return (
    <StepsContainer>
      <Step number={1} title={"Panda Dynasty NFT"} completed={passes.panda === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={2} title={"Magical Pumpkin"} completed={passes.pumpkin === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={3} title={"Comics Unlock"} completed={passes.zova === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={4} title={"Lucky Bamboo"} completed={passes.goldenbamboo === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={5} title={"The Washing Machine"} completed={passes.wmc === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={6} title={"Background Accessory"} completed={passes.accessory === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={7} title={"Time Machine"} completed={passes.tm === true} onClick={toggleStep} selected={selectedStep}/>
      <Step number={8} title={"Bamboo Ticket"} completed={passes.tickets === true} onClick={toggleStep} selected={selectedStep}/>
    </StepsContainer>
  );
}

export default StepsPanel
