import client from "./client";

const unlockAccessory = async (wallet) => {
  const response = await client.post("/accessory", {wallet: wallet});

  return response.data.correct === true;
};

const accessory = {
  unlockAccessory
};

export default accessory;
