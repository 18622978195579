import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: auto;
  padding: 0 20px;
  
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 40px;
  }
`;

const Container = ({children}) => {
  return (
    <StyledContentContainer>
      {children}
    </StyledContentContainer>
  )
};

export default Container;

