import React, {useEffect, useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import cardLargeImage from "../../resources/img/dashboard/card-large.png";
import actionFrameImage from "../../resources/img/dashboard/action-frame.png";
import Button from "../Dashboard/Button";

const ActionFrame = styled.div`
  background: url(${actionFrameImage}) no-repeat;
  background-size: 100% auto;
  width: 363px;
  height: 528px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Card = styled.div`
  background: url(${cardLargeImage}) no-repeat;
  background-size: 100% auto;
  width: 222px;
  height: 307px;
`;

const CardImage = styled.img`
  width: 158px;
  height: 158px;
  margin-top: 28px;
`;

const CardContent = styled.div`
  ${fonts.gloryBold};
  font-size: 14px;
  line-height: 26px;
  color: ${colors.grey};
  text-align: left;
  width: 158px;
  margin: 14px auto 0 auto;
`;

const ActionFrameButton = styled.div`
  position: absolute;
  bottom: -20px;
  text-align: center;
  width: 100%;
  transition: all 5s;

  button {
    margin: auto;
  }
`;

const ActionFramePanel = ({item, unlockAccessory}) => {
  const [isActionable, setIsActionable] = useState(false);
  const [action, setAction] = useState(null);
  const [cta, setCta] = useState(null);

  const actionableItems = [
    {
      "type": "Washing Machine",
      "action": "accessory",
      "cta": "Wash!"
    }
  ];

  useEffect(() => {
    let itemType;

    if (item && typeof item.metadata !== "undefined") {
      item.metadata.attributes.map((attribute) => {
        if (attribute["pass_type"] === "Pass Type") {
          itemType = attribute["value"];
        }
      });
    }

    let isActionable = false;
    let action;
    let cta;

    actionableItems.map((actionableItem) => {
      if (actionableItem.type === itemType) {
        isActionable = true;
        action = actionableItem.action;
        cta = actionableItem.cta;
      }
    })

    setIsActionable(isActionable);
    setAction(action);
    setCta(cta);
  }, [item]);

  const triggerAction = (e) => {
    e.preventDefault();

    if (action === "accessory") {
      unlockAccessory();
    }
  }

  let date = new Date();

  date.setMinutes(0, 0, 0, 0);
  date.setSeconds(0, 0, 0, 0);

  const imageCacheTimestamp = Date.parse(date.toString());

  return (
    <ActionFrame>
      {(item && typeof item.metadata !== "undefined") && <Card>
        <CardImage src={`${item.metadata.image}${item.metadata.image.includes("pandadynasty") ? "?" + imageCacheTimestamp : ""}`} alt={item.type}/>
        <CardContent>
          {item.name}
        </CardContent>
      </Card>}
      {isActionable &&
      <ActionFrameButton>
        <Button onClick={(e) => triggerAction(e)}>
          {cta}
        </Button>
      </ActionFrameButton>}
    </ActionFrame>
  );
}

export default ActionFramePanel
