import React, {useEffect, useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import ButtonLink from "../Home/ButtonLink";

const IntroContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 32px;
  text-align: left;
  min-height: 112px;
`;

const PassEntry = styled.div`
  display: ${(props) => props.selected === "true" ? "flex" : "none"};
  opacity: ${(props) => props.comingsoon === "true" ? "0.7" : "1"};
  flex-direction: column;
  max-width: 700px;
`;

const PassTitle = styled.h2`
  ${fonts.gloryBold};
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 4px;
`;

const PassText = styled.div`
  font-size: 18px;
  line-height: 26px;
  
  a {
    color: ${colors.white};
  }
`;

const Buttons = styled.div`
  display: flex;
  
  a {
    margin-right: 20px;
  }
`;

const IntroPanel = ({selectedStep}) => {
  return (
    <IntroContainer>
      <PassEntry selected={!selectedStep && "true"}>
        <PassTitle>Panda Passes</PassTitle>
        <PassText>Collect the 8 Panda Passes to complete the Quest.</PassText>
      </PassEntry>
      <PassEntry selected={selectedStep === 1 && "true"}>
        <PassTitle>Panda Dynasty NFT</PassTitle>
        <PassText><a href={"https://opensea.io/collection/pandadynasty"} target={"_blank"}>Grab your ticket</a> to proceed forward. By being a holder, the adventure begins.</PassText>
        <ButtonLink href={"https://opensea.io/collection/pandadynasty"}>
          Get a Panda
        </ButtonLink>
      </PassEntry>
      <PassEntry selected={selectedStep === 2 && "true"}>
        <PassTitle>Magical Pumpkin</PassTitle>
        <PassText>Merge your panda NFT with matching <a href={"https://opensea.io/collection/panda-pumpkins"} target={"_blank"}>pumpkin</a> of your own. Only 8 colors will win. Click <a href={"https://pandadynasty.io/pumpkinswap"}>here</a> to see the list of the winning colors</PassText>
        <Buttons>
          <ButtonLink href={"https://opensea.io/collection/panda-pumpkins"}>
            Get a Pumpkin
          </ButtonLink>
          <ButtonLink href={"https://pandadynasty.io/pumpkinswap"} blank={false}>
            Pumpkin Swap
          </ButtonLink>
        </Buttons>
      </PassEntry>
      <PassEntry selected={selectedStep === 3 && "true"}>
        <PassTitle>Comics unlock</PassTitle>
        <PassText>Thanks to <a href={"https://www.webtoons.com/en/challenge/panda-dynasty-age-of-the-dynasty/list?title_no=697468"} target={"_blank"}>clues</a> discovered in the comics you will <a href={"https://pandadynasty.io/pandapass"} target={"_blank"}>unlock a special NFT</a> that will help to open the treasure.</PassText>
        <ButtonLink href={"https://pandadynasty.io/pandapass"} blank={false}>
          Unlock Pass
        </ButtonLink>
      </PassEntry>
      <PassEntry selected={selectedStep === 4 && "true"}>
        <PassTitle>Lucky Bamboo Video Game Episode 1</PassTitle>
        <PassText>Players that finish the first episode of the game will receive a Lucky Bamboo NFT. But hurry; you must <a href={"https://pandadynasty.io/startgame"} target={"_blank"}>resolve the puzzle</a> quickly to proceed forward.</PassText>
        <ButtonLink href={"https://pandadynasty.io/play"} blank={false}>
          Play Game
        </ButtonLink>
      </PassEntry>
      <PassEntry selected={selectedStep === 5 && "true"}>
        <PassTitle>The Washing Machine</PassTitle>
        <PassText>Looks like we are ready to clean our accessories. Enter the foundry for your accessory Panda Pass.</PassText>
        <ButtonLink href={"https://pandadynasty.io/esr"} blank={false}>
          Escape Room
        </ButtonLink>
      </PassEntry>
      <PassEntry selected={selectedStep === 6 && "true"}>
        <PassTitle>Background Accessory</PassTitle>
        <PassText>Extract the accessory from your Panda NFT to create a Panda Pass. To unlock this pass, select a Washing Machine from step 6 and click the Wash button!</PassText>
      </PassEntry>
      <PassEntry selected={selectedStep === 7 && "true"}>
        <PassTitle>Time Machine Video Game Episode 2</PassTitle>
        <PassText>Be one of the first players to finish the second episode of the game and you’ll receive the time Machine NFT.</PassText>
        <ButtonLink href={"https://pandadynasty.io/play2"} blank={false}>
          Play Game
        </ButtonLink>
      </PassEntry>
      <PassEntry selected={selectedStep === 8 && "true"}>
        <PassTitle>Bamboo Ticket</PassTitle>
        <PassText>Last NFT is the Bamboo Ticket that will give you a chance to win a part of the Bamboo treasure</PassText>
        <ButtonLink href={"https://pandadynasty.io/pt"} blank={false}>
          Play Game
        </ButtonLink>
      </PassEntry>
    </IntroContainer>
  );
}

export default IntroPanel
