import React, {useState} from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import PopupFrameImage from "../../resources/img/dashboard/popup-frame.png";
import Button from "./Button";

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  display: ${(props) => props.show === "true" ? "block" : "none"};
`;

const OverlayBackground = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.grey};
  opacity: 0.6;
  position: absolute;
  z-index: 10;
`;

const PopupFrame = styled.div`
  width: 588px;
  height: 368px;
  background: url(${PopupFrameImage}) no-repeat;
  background-size: 100% auto;
  position: absolute;
  margin: 200px auto;
  top: 0;
  left: calc(50% - 294px);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupFrameContent = styled.div`
  max-width: 360px;
`;

const PopupFrameText = styled.div`
  ${fonts.gloryRegular};
  font-size: 18px;
  line-height: 26px;
  color: ${colors.grey};

  b {
    ${fonts.gloryBold};
  }
`;

const PopupFrameTitle = styled.div`
  ${fonts.gloryBold};
  font-size: 42px;
  line-height: 52px;
  color: ${colors.grey};
  margin-bottom: 10px;
`;

const FrameButton = styled.div`
  position: absolute;
  bottom: -8px;
  text-align: center;
  width: 100%;
  transition: all 5s;

  button {
    margin: auto;
  }
`;

const ActionFramePanel = ({show, title, children, action, actionCta}) => {
  const callAction = (e) => {
    e.preventDefault();

    action();
  }

  return (
    <OverlayContainer show={show}>
      <PopupFrame>
        <PopupFrameContent>
          <PopupFrameTitle>
            {title}
          </PopupFrameTitle>
          <PopupFrameText>
            {children}
          </PopupFrameText>
        </PopupFrameContent>
        {(action && actionCta) && <FrameButton>
          <Button onClick={(e) => callAction(e)}>
            {actionCta}
          </Button>
        </FrameButton>}
      </PopupFrame>
      <OverlayBackground/>
    </OverlayContainer>
  );
}

export default ActionFramePanel
