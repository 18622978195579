import client from "./client";

const getDashboard = async (wallet) => {
  const response = await client.post("/dashboard", {wallet: wallet});

  return response.data;
};

const dashboard = {
  getDashboard
};

export default dashboard;
